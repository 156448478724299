<!-- eslint-disable vue/no-v-html -->
<template>
	<w-layout fill-height column>
		<w-flex v-show="!mini" shrink mt-3 px-3>
			<w-layout column justify-center>
				<w-flex>
					<w-layout row justify-center align-center>
						<w-flex shrink>
							<w-btn icon="add" @click="createMobileApp">
								{{ $t('actions.create') }}
							</w-btn>
						</w-flex>
					</w-layout>
				</w-flex>
				<w-spacer />
				<w-search-input v-model="search" :label="$t('actions.search')" />
			</w-layout>
		</w-flex>
		<!-- SAFARI BUG FIX : DO NOT REMOVE height: 0 ! -->
		<w-flex grow scroll-y style="height: 0">
			<w-layout fill-height column>
				<!-- SAFARI BUG FIX : DO NOT REMOVE height: 0 ! -->
				<w-flex grow scroll-y style="height: 0">
					<w-virtual-list :dense="mini" :items="applications_filtered">
						<template v-slot:default="{ item: mobileApp }">
							<v-hover>
								<template v-slot:default="{}">
									<w-list-tile
										:key="mobileApp.id"
										:value="selectedApplication && mobileApp.id == selectedApplication.id"
										avatar
										@click="editMobileApp(mobileApp)"
									>
										<w-list-tile-avatar v-if="mini">
											<v-tooltip right>
												<template v-slot:activator="{ on }">
													<w-layout>
														<w-flex v-on="on">
															<MobileAppIcon :value="mobileApp" readonly :size="48" />
														</w-flex>
													</w-layout>
												</template>
												<span v-text="mobileApp.title"></span>
											</v-tooltip>
										</w-list-tile-avatar>
										<w-list-tile-avatar v-else>
											<MobileAppIcon :value="mobileApp" readonly :size="48" />
										</w-list-tile-avatar>
										<w-list-tile-content v-show="!mini">
											<w-list-tile-title v-html="mobileApp.formatted_name"></w-list-tile-title>
										</w-list-tile-content>
									</w-list-tile>
								</template>
							</v-hover>
						</template>
					</w-virtual-list>
				</w-flex>
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'MobileAppList',
	components: {
		MobileAppIcon: () => ({
			component: import('@/components/SuperAdmin/MobileApp/Details/MobileAppIcon')
		})
	},
	mixins: [SuperAdminModuleGuard],
	data: function () {
		return {
			loading: true,
			mini: false,
			sidebar: false,
			search: ''
		}
	},
	computed: {
		...mapState({
			applications: state => state.mobileapps.list,
			selectedApplication: state => state.mobileapps.selected
		}),
		applications_formatted: function () {
			return this.applications.map(application => {
				return JSON.parse(JSON.stringify(application))
			})
		},
		applications_filtered: function () {
			const search = this.search.trim().toLowerCase()
			const searchedTextRegEx = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi')
			return this.applications
				.filter(application => application.title?.toLowerCase().includes(search))
				.map(application => {
					application.formatted_name = search && search.length ? application.title.replace(searchedTextRegEx, '<b>$&</b>') : application.title
					return application
				})
				.sort((a, b) => {
					const textA = a.title.toUpperCase()
					const textB = b.title.toUpperCase()
					return textA < textB ? -1 : textA > textB ? 1 : 0
				})
		}
	},
	watch: {
		mini: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		},
		sidebar: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		}
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.SIDEBAR_MINIMIZED, action: this.onSidebarMinimized },
				{ event: this.appEvents.SIDEBAR_MAXIMIZED, action: this.onSidebarMaximized },
				{ event: this.appEvents.SHOW_SIDEBAR, action: this.onShowSideBar },
				{ event: this.appEvents.HIDE_SIDEBAR, action: this.onHideSideBar }
			]
		},
		onSidebarMaximized: function () {
			this.mini = false
		},
		onSidebarMinimized: function () {
			this.mini = true
		},
		onShowSideBar: function () {
			this.sidebar = true
		},
		onHideSideBar: function () {
			this.sidebar = false
		},
		createMobileApp: function () {
			this.eventBus.emit(this.events.CREATE_NEW_MOBILE_APP)
		},
		editMobileApp: function (mobileApp) {
			if (mobileApp?.id) {
				this.eventBus.emit(this.events.SELECTED_MOBILE_APP, mobileApp.id)
			}
		}
	}
}
</script>

<style>
.app-logo-icon {
	border-radius: 10px !important;
}
.app-logo-icon .v-image__image {
	border-radius: 10px !important;
}
</style>
